import React from 'react';
import PropTypes from 'prop-types';
import ReactMoment from 'react-moment';
import Tooltip from '@material-ui/core/Tooltip';

const RelativeMoment = ({ tooltipDateFormat, children }) => (
  <span>
    <Tooltip
      title={
        <>
          <ReactMoment format={tooltipDateFormat}>{children}</ReactMoment>
        </>
      }
    >
      <ReactMoment fromNow>{children}</ReactMoment>
    </Tooltip>
  </span>
);

RelativeMoment.propTypes = {
  tooltipDateFormat: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.object]).isRequired,
};

RelativeMoment.defaultProps = {
  tooltipDateFormat: 'MMMM Do, YYYY',
};

export default RelativeMoment;
